
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';

export default defineComponent({
  name: 'MinhaDashboard',
  components: {
  },
  setup() {
    const { telaBase } = useTelaBase();

    const state = reactive({
      nomeUsuario: '',
      apresentarGerenciadorAnuncios: false,
    });

    onBeforeMount(async () => {
      state.nomeUsuario = storeSistema.getters.nomeUsuarioAutenticado();
    });

    return {
      state,
      telaBase,
      storeSistema,
      UtilitarioData,
      UtilitarioGeral,
    };
  },
});
